import React from "react";
import { graphql } from "gatsby";
import "../styles/styles.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import loadable from "@loadable/component";

const Layout = loadable(() => import("@common/Layout/Layout"));
const Home = loadable(() => import("@components/Home/Home"));
const SEO = loadable(() => import("@components/Common/SEO/SEO"));
const IndexPage = ({ path, data }) => (
    <>
        <SEO
            title="The Best Web App and Mobile App Software Development Partners"
            SEOData={data.allStrapiSeoDescriptions?.nodes}
            titleTemplate="Emerging technologies made simple"
            description="When choosing software development partners, pick Impressit. Our team of technology experts delivers bespoke product development and dedicated teams services"
        />
        <Layout path={path} isNoPadding>
            <Home caseStudies={data.allStrapiCaseStudies} />
        </Layout>
    </>
);

export default IndexPage;

export const homeCaseStudiesQuery = graphql`
    query homeAllCaseStudiesQuery {
        allStrapiCaseStudies {
            nodes {
                id
                logo {
                    url
                }
                wideImage {
                    url
                }
                metaUrl
                title
            }
        }
        allStrapiSeoDescriptions {
            nodes {
                Title
                MetaTitle
                PageUrl
            }
        }
    }
`;
